import Blockly from 'blockly';

const directionMap = {
    IN_FRONT: '0',
    ON_THE_LEFT: '-1',
    ON_THE_RIGHT: '1'
};

const conditionMap = {
    WALL: '=== OUTSIDE',
    NO_WALL: '!== OUTSIDE',
    DIRTY_SQUARE: '=== DIRTY',
    CLEAN_SQUARE: '=== CLEAN'
};

const quarterTurnsMap = {
    TURN_LEFT: '-1',
    TURN_RIGHT: '1',
    TURN_BACK: '2'
};

const loopStopCode =
    '  if (!window.algolabRunning) {\n' +
    '    throw { name: "stop" };\n' +
    '  }\n';

Blockly.JavaScript['robovac_repeat_until_clean'] = function(block) {
    let code =
        'while(!room.isClean()) {\n' +
        Blockly.JavaScript.statementToCode(block, 'DO') +
        loopStopCode +
        '}\n';
    return code;
}

Blockly.JavaScript['robovac_repeat'] = function(block) {
    let repeatKind = block.getFieldValue('REPEAT_KIND');
    let negate = repeatKind === 'UNTIL' ? '!' : '';
    let direction = directionMap[block.getFieldValue('DIRECTION')];
    let condition = conditionMap[block.getFieldValue('CONDITION')];
    let code =
        'while (' + negate + '(robot.sense(room, ' + direction + ') ' + condition + ')) {\n' +
        Blockly.JavaScript.statementToCode(block, 'DO') +
        loopStopCode +
        '}\n';
    return code;
};

Blockly.JavaScript['robovac_repeat_times'] = function(block) {
    let repetitions = block.getFieldValue('REPETITIONS');
    let code =
        'for (let robovac_dummy_repeat of Array(' + repetitions + ').keys()) {\n' +
        Blockly.JavaScript.statementToCode(block, 'DO') +
        loopStopCode +
        '}\n';
    return code;
};

Blockly.JavaScript['robovac_if'] = function(block) {
    let direction = directionMap[block.getFieldValue('DIRECTION')];
    let condition = conditionMap[block.getFieldValue('CONDITION')];
    let code =
        'if (robot.sense(room, ' + direction + ') ' + condition + ') {\n' +
        Blockly.JavaScript.statementToCode(block, 'THEN') +
        '}\n';
    return code;
};

Blockly.JavaScript['robovac_if_else'] = function(block) {
    let direction = directionMap[block.getFieldValue('DIRECTION')];
    let condition = conditionMap[block.getFieldValue('CONDITION')];
    let code =
        'if (robot.sense(room, ' + direction + ') ' + condition + ') {\n' +
        Blockly.JavaScript.statementToCode(block, 'THEN') +
        '} else {\n' +
        Blockly.JavaScript.statementToCode(block, 'ELSE') +
        '}\n';
    return code;
};

Blockly.JavaScript['robovac_move'] = function(block) {
    let code =
        'robot.move(1);\n' +
        'if (room.squares[robot.y][robot.x] === OUTSIDE) {\n' +
        '  throw { name: "crash" };\n' +
        '}\n' +
        'await new Promise(r => setTimeout(r, sleep_time));\n' +
        'room.clean(robot.x, robot.y);\n';
    return code;
};

Blockly.JavaScript['robovac_rotate'] = function (block) {
    let quarterTurns = quarterTurnsMap[block.getFieldValue('ANGLE')];
    let code =
        'robot.rotate(' + quarterTurns + ');\n' +
        'await new Promise(r => setTimeout(r, sleep_time));\n';
    return code;
};
